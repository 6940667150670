import { matchSorter } from "match-sorter";
// @ts-expect-error - no types, but it's a tiny function
import sortBy from "sort-by";
import jsonCities from "./cities.json";
import jsonHealthInsurances from "./health-insurance.json";
import jsonSpecialties from "./specialties-ids.json";
import jsonStates from "./uf.json";

interface HealthInsurance {
  id: number;
  name: string;
}
interface Specialties {
  id: number;
  name: string;
}
export interface States {
  name: string;
  uf: string;
  cities: City[];
}
interface City {
  id: number;
  name: string;
}

const specialties = {
  records: {} as Record<string, Specialties>,

  async getAll(): Promise<Specialties[]> {
    return Object.values(specialties.records).filter(
      (value): value is Specialties => value !== undefined,
    );
  },
  async get(id: string): Promise<Specialties | null> {
    return specialties.records[id] || null;
  },
  async create(values: Specialties): Promise<Specialties> {
    const id = values.id || Math.random().toString(36).substring(2, 9);
    const newContact = { ...values };
    specialties.records[id] = newContact;
    return newContact;
  },
};

const healthInsurances = {
  records: {} as Record<string, HealthInsurance>,

  async getAll(): Promise<HealthInsurance[]> {
    return Object.values(healthInsurances.records).filter(
      (value): value is HealthInsurance => value !== undefined,
    );
  },
  async get(id: string): Promise<HealthInsurance | null> {
    return healthInsurances.records[id] || null;
  },
  async create(values: HealthInsurance): Promise<HealthInsurance> {
    const id = values.id || Math.random().toString(36).substring(2, 9);
    const newContact = { ...values };
    healthInsurances.records[id] = newContact;
    return newContact;
  },
};

const stateAndCities = {
  records: {} as Record<string, States>,

  async getAll(): Promise<States[]> {
    return Object.values(stateAndCities.records).filter(
      (value): value is States => value !== undefined,
    );
  },
  async get(id: string): Promise<States | null> {
    return stateAndCities.records[id] || null;
  },

  async create(values: States): Promise<States> {
    const newState = { ...values };
    stateAndCities.records[values.name] = newState;
    return newState;
  },
};

export async function getHealthInsurances(
  query?: string | null,
): Promise<HealthInsurance[]> {
  let he = await healthInsurances.getAll();
  if (query) {
    he = matchSorter(he, query, {
      keys: ["name"],
    });
  }
  return he.sort(sortBy("first", "name"));
}
export async function getSpecialties(
  query?: string | null,
): Promise<Specialties[]> {
  let he = await specialties.getAll();
  if (query) {
    he = matchSorter(he, query, {
      keys: ["name"],
    });
  }
  return he.sort(sortBy("first", "name"));
}
export async function getStatesAndCities(
  query?: string | null,
): Promise<States[]> {
  let he = await stateAndCities.getAll();
  if (query) {
    he = matchSorter(he, query, {
      keys: ["nome"],
    });
  }
  return he.sort(sortBy("first", "nome"));
}

export async function getCities(query?: string | null): Promise<UF[]> {
  let he = await stateAndCities.getAll();
  if (query) {
    he = matchSorter(he, query, {
      keys: ["nome"],
    });
  }
  return he.sort(sortBy("first", "name"));
}
for (const item of jsonHealthInsurances) {
  healthInsurances.create({ ...item });
}

for (const item of jsonSpecialties) {
  specialties.create({ ...item });
}

const statesMap: Record<string, States> = {};

for (const item of jsonCities) {
  const { id, name, uf } = item;
  const state = uf.toUpperCase();

  if (!statesMap[state]) {
    statesMap[state] = {
      name: jsonStates.find((s) => s.uf === uf)?.nome ?? "",
      uf: state,
      cities: [],
    };
  }

  statesMap[state]?.cities.push({ id, name });
}

for (const state of Object.values(statesMap)) {
  stateAndCities.create(state);
}
